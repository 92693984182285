import React, { Component, Fragment } from 'react';
import '../App.css';
import { getRequestParams } from '../../src/helpers/globalfunctions.js';
import jsSHA from "jssha";
import LoadingOverlay from 'react-loading-overlay';

export class LoginComponent extends Component {
    static displayName = LoginComponent.name;

    constructor(props) {
        super(props);
        const queryString = require('query-string');
        var parsed = queryString.parse(this.props.location.search);
        this.state = {
            loadPanelVisible: false,
            isSubmited: false,
            userName: '',
            prevUserName: '',
            password: '',
            message: '',
            stationCode: '',
            stationName: '',
            userToken: this.props.location.search != "" ? parsed.userguid : "",
            stations: [],
            errors: {
                userName: '',
                password: '',
                stationCode: ''
            },
        }
    }

    componentDidMount() {
        //await this.getSourceStation();
    }

    getSourceStation = async () => {
        try {
            if ((this.state.userName + "").trim().length === 0) {
                this.setState({ stations: [] });
                return;
            }
            if ((this.state.userName + "").trim() === (this.state.prevUserName + "").trim()) {
                return;
            }
            let accountId = process.env.REACT_APP_ACCOUNT_ID;
            let locationId = process.env.REACT_APP_LOCATION_ID;
            const requestParams = getRequestParams('GET');
            this.setState({ loadPanelVisible: true });
            const response1 = await fetch(process.env.REACT_APP_API_BASE_URL + 'authentication/GetAccountId/' + (this.state.userName + "").trim(), requestParams);
            const data1 = await response1.json();
            if (data1.isSuccess) {
                accountId = data1.payload.accountId;
                const response = await fetch(process.env.REACT_APP_TRIPS_API_BASE_URL + 'TrainTrip/PlatformIndicator/Stations/' + accountId + "/" + locationId, requestParams);
                const data = await response.json();
                this.setState({
                    stations: data != null && data.payload.length > 0 ? data.payload : [],
                    prevUserName: this.state.userName,
                    loadPanelVisible: false
                });
            } else {
                this.setState({ stations: [], loadPanelVisible: false });
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".getSourceStation function", err);
            this.setState({ stations: [], loadPanelVisible: false });
        }
    }

    // handleSourceStationChange = (e) => {
    //   this.setState({
    //     stationCode: (e.value != null || e.value != '' ? e.value : ''),
    //     stationName: (e.value != null || e.value != '' ? e.label : ''),
    //   })
    //   let errors = this.state.errors;
    //   errors.stationCode = "";
    //   this.setState({ errors: errors });
    // }

    handleInputChange = async (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: value });

        if (name == "stationCode") {
            if (value != '') {
                var _stationName = this.state.stations.length > 0 ? this.state.stations.filter(k => k.code == value)[0] : '';
                this.setState({ stationName: _stationName.name });
            }
            else {
                this.setState({ stationName: '' });
            }
        }
    }

    onUserNameBlur = async (event) => {
        await this.getSourceStation();
    }

    handleValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.userName = "";
            errors.password = "";
            errors.stationCode = "";
            this.setState({ message: '' });

            if (this.state.userName === '') {
                formIsValid = false;
                errors.userName = "Please enter username.";
            }

            if (this.state.password === '') {
                formIsValid = false;
                errors.password = "Please enter password.";
            }

            if (this.state.stationCode === '') {
                formIsValid = false;
                errors.stationCode = "Please select source station.";
            }

            this.setState({ errors: errors });
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleValidation function", err);
            formIsValid = false;
        }
        return formIsValid;
    }

    handleSubmit = async (e) => {
        try {
            e.preventDefault();
            if (this.handleValidation()) {
                this.setState({ isSubmited: true, message: '' });
                this.login();
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleSubmit function", err);
            this.setState({ isSubmited: false, message: "Oops something went worng !!!" });
        }
    }

    handleSubmit = async (e) => {
        try {
            e.preventDefault();
            if (this.handleValidation()) {
                this.setState({ isSubmited: true, message: '' });
                this.login();
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleSubmit function", err);
            this.setState({ isSubmited: false, message: "Oops something went worng !!!" });
        }
    }

    login = async () => {
        try {
            let passwordHash = "";
            var token = new Object();
            token.UserName = this.state.userName;
            const tokenRequestParams = getRequestParams('POST', token);
            //console.log(process.env.REACT_APP_API_BASE_URL);

            this.setState({
                loadPanelVisible: true
            });

            const tokenResponse = await fetch(process.env.REACT_APP_API_BASE_URL + 'authentication/password-token-request', tokenRequestParams);
            const tokenData = await tokenResponse.json();

            if (tokenData.isSuccess) {
                passwordHash = this.calcHash(this.state.password, tokenData.payload.token);
            } else {
                this.setState({ isSubmited: false, message: tokenData.message, loadPanelVisible: false });
                return;
            }

            var request = new Object();
            request.UserName = this.state.userName;
            request.Password = this.state.userToken != "" ? this.state.userToken : passwordHash;
            request.IpAddress = this.state.ipAddress;
            request.Latitude = this.state.latitude;
            request.Longitude = this.state.longitude;
            request.IsAdminUser = false;
            const requestParams = getRequestParams('POST', request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'authentication', requestParams);
            const data = await response.json();
            this.setState({ loadPanelVisible: false });
            if (data.isSuccess) {
                sessionStorage.setItem("session_token", data.token);
                sessionStorage.setItem("session_userId", data.payload.userContext.userId);
                sessionStorage.setItem("session_userName", data.payload.userContext.userName);
                sessionStorage.setItem("session_name", data.payload.userContext.fullName);
                sessionStorage.setItem("session_email", data.payload.userContext.email);
                sessionStorage.setItem("session_accountId", data.payload.userContext.accountId);
                sessionStorage.setItem("session_accountname", data.payload.userContext.accountName);
                sessionStorage.setItem("session_locationId", data.payload.userDefaultLocations[0].locationId);
                sessionStorage.setItem("session_PageName", "Train List");
                sessionStorage.setItem("session_stationCode", this.state.stationCode);
                sessionStorage.setItem("session_stationName", this.state.stationName);
                window.location.href = "/dashboard";
            }
            else {
                this.setState({ isSubmited: false, message: data.message, loadPanelVisible: false });
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".login function", err);
            this.setState({ isSubmited: false, message: "Unable to login. Something went wrong.", loadPanelVisible: false });
        }
    }

    calcHash(value, securityKey) {
        try {
            let shaObj = new jsSHA("SHA-256", "TEXT", { encoding: "UTF8" });
            shaObj.update(value);
            let saltText = shaObj.getHash("HEX");

            let shaObj2 = new jsSHA("SHA-256", "TEXT", { encoding: "UTF8" });
            shaObj2.update(saltText + securityKey);

            return shaObj2.getHash("HEX");
        } catch (err) {
            console.log("Error in " + this.displayName + ".calcHash function", err);
        }
    }

    uuidv4() {
        return '00-0-4-1-000'.replace(/[^-]/g,
            s => ((Math.random() + ~~s) * 0x10000 >> s).toString(16).padStart(4, '0')
        );
    }


    render() {
        return (
            <Fragment>
                <LoadingOverlay
                    active={this.state.loadPanelVisible}
                    spinner
                    text='Request in process ... Please wait ...'>
                    <form className="form-signin">
                        <div className='mb-3 text-center'><img className="mb-4" src="../img/logo_sst_utwiz2.png" alt="SilverStar | UTWiz" width="350" height="" /></div>
                        <div className="form-label-group">
                            <input type="text" id="inputUsername" tabIndex="1" className="form-control" placeholder="Username" required="" autoFocus="" name="userName" value={this.state.userName} onChange={this.handleInputChange} onBlur={this.onUserNameBlur} />
                            <label htmlFor="inputUsername">Username</label>
                        </div>
                        {this.state.errors["userName"].length > 0 && <span className='error-msg'>{this.state.errors["userName"]}</span>}
                        <div className="form-label-group">
                            <input type="password" id="inputPassword" tabIndex="2" className="form-control" placeholder="Password" required="" name="password" value={this.state.password} onChange={this.handleInputChange} />
                            <label htmlFor="inputPassword">Password</label>
                        </div>
                        {this.state.errors["password"].length > 0 && <span className='error-msg'>{this.state.errors["password"]}</span>}
                        <div className="form-label-group">
                            <select name="stationCode" className="form-control" placeholder="I am at" onChange={this.handleInputChange} tabIndex="3">
                                <option value="">I am at</option>
                                {
                                    this.state.stations.map((item) => (
                                        <option key={item.code} value={item.code}>{item.name}</option>
                                    ))}
                            </select>
                        </div>
                        {this.state.errors["stationCode"].length > 0 && <span className='error-msg'>{this.state.errors["stationCode"]}</span>}
                        <div>
                            <button className="btn btn-lg btn-primary btn-block" type="submit" tabIndex="4" onClick={this.handleSubmit} disabled={this.state.isSubmited}>Login</button>
                        </div>
                        <div>
                            {this.state.message.length > 0 && <span className='error-msg'>{this.state.message}</span>}
                        </div>
                        <div className='text-center mt-4 align-middle'><small className='text-muted'>Powered by</small> <img className="mb-4 align-middle" src="../img/logo-plexitech2.png" alt="PlexiTech" width="150" height="" /></div>
                    </form>
                </LoadingOverlay>
            </Fragment>
        );
    }
}